<template>
    <div class="filter">
        <h2>笔记</h2>
        <div class="search-box">
            <a-select v-model="defaultValue">
                <a-select-option
                    v-for="item in searchType"
                    :key="item.value"
                    :value="item.key"
                >
                    {{ item.name }}
                </a-select-option>
            </a-select>
            <a-input-search
                v-model="value"
                placeholder="输入关键词搜索"
                @search="onSearch"
            />
        </div>
        <div class="result">
            <div>
                <span>关键词：</span>
                <span class="red">{{ value }}</span>
            </div>
            <div>
                <span>检索结果：</span>
                <span>{{ total }} 个结果</span>
            </div>
        </div>
        <div class="list">
            <a-list :data-source="result" :loading="loading">
                <a-list-item
                    slot="renderItem"
                    slot-scope="item"
                    @click.native.stop="scrollToEle(item, $event)"
                    :style="`--color:${item.Color || 'red'};`"
                >
                    <div class="title">
                        <div class="dot" v-html="item.Title"></div>
                        <a-popconfirm
                            title="是否删除？"
                            ok-text="是"
                            cancel-text="否"
                            @confirm="deleteNote(item)"
                        >
                            <svg-icon icon-class="icon_delete"></svg-icon>
                        </a-popconfirm>
                        &nbsp;
                        <a-tooltip @click="check(item)">
                            <template slot="title">查看</template>
                            <svg-icon icon-class="icon_eye"></svg-icon>
                        </a-tooltip>
                    </div>
                    <div class="title">
                        <div class="dot" v-html="item.Txt"></div>
                    </div>

                    <div class="des" v-html="item.Note"></div>
                </a-list-item>
            </a-list>
            <Pagination
                :pageSize="params.pageSize"
                :total="total"
                @pageChange="pageChange"
                v-if="result.length > 0"
                :current="params.pageIndex"
            />
        </div>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import { GetUserNoteListByCardId, DelNote } from '@/api/card'
export default {
    props: ['curType', 'treeData'],
    components: {
        Pagination,
    },
    data() {
        return {
            total: 0,
            params: {
                cardId: '',
                pageIndex: 1,
                pageSize: 5,
            },
            result: [],
            cardType: [],
            loading: false,
            searchType: [
                {
                    name: '全文',
                    value: 0,
                    key: 'fullText',
                },
                {
                    name: '标题',
                    value: 1,
                    key: 'title',
                },
                {
                    name: '选中内容',
                    value: 2,
                    key: 'txt',
                },
                {
                    name: '内容',
                    value: 3,
                    key: 'content',
                },
            ],
            defaultValue: 'fullText',
            value: '',
        }
    },
    methods: {
        onSearch() {
            this.params.pageIndex = 1
            let form = JSON.parse(JSON.stringify(this.params))
            this.$set(form, this.defaultValue, this.value)
            this.getList(form)
        },

        async getList(params) {
            this.loading = true
            await GetUserNoteListByCardId(params)
                .then(res => {
                    const { data, total } = res
                    if (this.value) {
                        data.forEach(element => {
                            element.Title = element.Title.replace(
                                new RegExp(this.value, 'g'),
                                "<span style='color:red;'>" +
                                    this.value +
                                    '</span>'
                            )
                            element.Note = element.Note.replace(
                                new RegExp(this.value, 'g'),
                                "<span style='color:red;'>" +
                                    this.value +
                                    '</span>'
                            )
                            element.Txt = element.Txt.replace(
                                new RegExp(this.value, 'g'),
                                "<span style='color:red;'>" +
                                    this.value +
                                    '</span>'
                            )
                            console.log(element)
                        })
                    }
                    this.result = data
                    this.total = total
                    console.log(this.result)
                })
                .catch(e => e)
            this.loading = false
        },
        pageChange({ page, pageSize }) {
            this.params.pageIndex = page
            this.getList(this.params)
        },
        // 跳转到模型内的相应部分
        scrollToEle(item, event) {
            if (
                event.target.nodeName == 'svg' ||
                event.target.nodeName == 'use'
            )
                return
            // const { CardId } = item
            // const ele = $('#' + CardId)
            // const parent = ele.parents('.model_main')
            // parent.animate({ scrollTop: ele[0].offysetTop }, 500)
            this.$emit('selectItem', item)
            const { CardId } = item
            const ele = $('#test' + CardId)
            const parent = ele.parents('.editor').children()
            setTimeout(() => {
                console.log($('.pos' + item.Id)[0].offsetTop, ele[0].offsetTop)
                if ($('.pos' + item.Id)[0]) {
                    parent.animate(
                        {
                            scrollTop:
                                $('.pos' + item.Id)[0].offsetTop +
                                ele[0].offsetTop -
                                30,
                        },
                        500
                    )
                }
            }, 100)
        },
        deleteNote(item) {
            DelNote(item.Id)
                .then(res => {
                    this.$message.success('删除成功！')
                    this.getList(this.params)
                })
                .catch(e => e)
        },
        removeItem() {
            this.$emit('selectItem', {})
        },
        check(item) {
            this.$emit('checkItem', { item, type: 'note' })
        },
    },
    created() {
        this.params.cardId = this.$route.params.id
        this.getList(this.params)
        document.addEventListener('click', this.removeItem)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.removeItem)
    },
}
</script>
<style scoped lang="less">
@import '~@/assets/css/right.less';
</style>
