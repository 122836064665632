<template>
    <div class="card">
        <a-button
            type="primary"
            class="goback"
            @click="goback"
            v-if="$route?.query?.isFromModel"
        >
            <a-icon type="left" />
            返回
        </a-button>
        <div class="auto-1440">
            <Breadcrumb :title="cardInfo.Name" type="grey" />
            <div class="main">
                <div class="left">
                    <div class="title">
                        <h1>
                            <!-- 卡片类型 -->
                            <template v-for="item in cardType">
                                <span
                                    class="car_type"
                                    :key="item.key"
                                    v-if="item.key == cardInfo.Type"
                                    :style="
                                        'background-color:' + item.color + ';'
                                    "
                                >
                                    {{ item.value }}
                                </span>
                            </template>

                            <span class="h1">{{ cardInfo.Name }}</span>
                            <a-tooltip>
                                <template slot="title">
                                    <span v-if="cardInfo.IsCollection">
                                        取消收藏
                                    </span>
                                    <span v-else>收藏</span>
                                </template>
                                <div
                                    class="collect"
                                    @click="toggleCollect"
                                    :class="
                                        cardInfo.IsCollection ? 'active' : ''
                                    "
                                >
                                    <svg-icon
                                        icon-class="icon_collect"
                                    ></svg-icon>
                                </div>
                            </a-tooltip>
                        </h1>
                        <div class="info_box">
                            <div class="label">标签：</div>
                            <div class="info_main tag_box">
                                <Tag
                                    type="default"
                                    :name="item"
                                    v-for="(item, index) in tags"
                                    :key="index"
                                />
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="label">来源：</div>
                            <div class="info_main level">
                                {{ cardInfo.Source }}
                            </div>
                        </div>
                        <div class="info_box">
                            <div class="label">学习程度：</div>
                            <div class="info_main progress">
                                <Progress :level="cardInfo.Level" />
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="btn_box">
                            <div
                                v-for="item in leftTab"
                                :key="item.value"
                                class="item"
                                :class="item.value == curtab ? 'active' : ''"
                                @click="changeTab(item)"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                        <div class="info" @click="curtab = null">
                            <transition name="list" mode="out-in">
                                <div
                                    class="left-pop"
                                    v-show="curtab == 0 || curtab == 1"
                                    @click.stop=""
                                >
                                    <NoteList
                                        ref="NotesList"
                                        v-if="curtab == 0"
                                        key="NoteList"
                                        @selectItem="getSelectedNote"
                                        @checkItem="getCheckedItem"
                                    />
                                    <FeedbackList
                                        v-if="curtab == 1"
                                        ref="FeedbackList"
                                        key="FeedbackList"
                                        @selectItem="getSelectedNote"
                                        @checkItem="getCheckedItem"
                                    />
                                </div>
                            </transition>
                            <InlineEditor
                                :id="id"
                                :data="cardInfo.Content"
                                @feedback="addFeedback"
                                @notes="addNotes"
                                @remark="showRemark"
                                :isEditable="true"
                                v-if="cardInfo.Content"
                                :selectItem="selectedNote"
                            />
                            <a-empty v-else />
                            <!-- <div v-html="cardInfo.Content" v-if="cardInfo.Content"></div> -->
                        </div>
                    </div>
                </div>
                <div class="right">
                    <RelateModel />
                    <RelateCard />
                </div>
            </div>
            <!-- 批注弹窗 -->
            <RemarkPop
                :visable="popVisible"
                @close="closeRemark"
                :positon="popPos"
                :id="remarkId"
            />
            <!-- 笔记 -->
            <NotesDialog
                :dialogVisable="notesVisable"
                @close="closeNotesDialog"
                :info="cardInfo"
                :selection="selection"
                :start="start"
                :end="end"
                @success="addNotesSuccess"
                :data="checkItem.item"
            />
            <!-- 反馈 -->
            <FeedbackDialog
                :dialogVisable="feedVisable"
                @close="closeFeedDialog"
                :info="cardInfo"
                :selection="selection"
                @success="addFeedSuccess"
                :start="start"
                :end="end"
                :data="checkItem.item"
            />
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb';
import Tag from '@/components/common/Tag';
import RelateModel from '@/components/Learning/Card/RelateModel';
import RelateCard from '@/components/Learning/Card/RelateCard';
import Progress from '@/components/Learning/Card/Progress';
import InlineEditor from '@/components/common/InlineEditor';
import RemarkPop from '@/components/common/RemarkPop';
import NotesDialog from '@/components/Learning/Model/NotesDialog';
import FeedbackDialog from '@/components/Learning/Model/FeedbackDialog';
import NoteList from '@/components/Learning/Card/NoteList';
import FeedbackList from '@/components/Learning/Card/FeedbackList';

import { GetData } from '@/api/card';
import { ToggleCollectCard } from '@/api/collection';
export default {
    metaInfo() {
        return {
            title: this.cardInfo.SEOTitle || this.cardInfo.Name || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.cardInfo.SEOKeyword,
                },
                {
                    name: 'description',
                    content: this.cardInfo.SEODescription,
                },
            ],
        };
    },
    components: {
        Breadcrumb,
        Tag,
        RelateModel,
        RelateCard,
        Progress,
        InlineEditor,
        RemarkPop,
        NotesDialog,
        FeedbackDialog,
        NoteList,
        FeedbackList,
    },
    computed: {
        tags() {
            this.cardInfo.Tags = this.cardInfo.Tags || '';
            return this.cardInfo.Tags.split(',');
        },
        id() {
            return this.$route.params.id;
        },
    },
    data() {
        return {
            cardInfo: {},
            cardType: [],
            // 批注
            popVisible: false,
            // 笔记
            notesVisable: false,
            // 反馈
            feedVisable: false,
            popPos: {
                x: 0,
                y: 0,
            },
            selection: '',
            remarkId: '',

            leftTab: [
                {
                    value: 0,
                    name: '个人笔记',
                },
                {
                    value: 1,
                    name: '内容反馈',
                },
            ],
            curtab: null,
            start: 0,
            end: 0,
            selectedNote: {},
            checkItem: {},
        };
    },
    methods: {
        async getData() {
            const { id, treeNum } = this.$route.params;
            const { data } = await GetData({ id, treeNum });
            this.cardInfo = data;
        },
        changeTab(item) {
            console.log(item);
            if (item.value == this.curtab) {
                this.curtab = null;
            } else {
                this.curtab = item.value;
            }
        },
        closeNotesDialog() {
            this.notesVisable = false;
        },
        addNotes({ id, selection, start, end }) {
            this.selection = selection;
            this.notesVisable = true;
            this.start = start;
            this.end = end;
        },

        // 批注弹窗显示
        showRemark({ e, id }) {
            this.remarkId = id;
            const { clientX, clientY } = e;
            this.popPos.x = clientX - 10;
            this.popPos.y = clientY + 20;
            this.popVisible = true;
        },
        closeRemark() {
            this.popVisible = false;
        },
        // 打开反馈弹窗
        addFeedback({ id, selection, start, end }) {
            this.selection = selection;
            this.feedVisable = true;
            this.start = start;
            this.end = end;
        },
        // 关闭反馈弹窗
        closeFeedDialog() {
            this.feedVisable = false;
        },
        addNotesSuccess() {
            this.notesVisable = false;
            console.log(this.$refs.NotesList);
            if (!this.$refs.NotesList) return;
            this.$refs.NotesList.onSearch();
        },
        addFeedSuccess() {
            this.feedVisable = false;
            if (!this.$refs.FeedbackList) return;
            this.$refs.FeedbackList.onSearch();
        },
        toggleCollect() {
            ToggleCollectCard(this.cardInfo.Id).then(res => {
                this.$message.success(res.msg);
                this.cardInfo.IsCollection = !this.cardInfo.IsCollection;
            });
        },
        getSelectedNote(item) {
            this.selectedNote = item;
        },
        //
        getCheckedItem(item) {
            console.log(item);
            this.checkItem = item;
            if (item.type == 'note') {
                this.notesVisable = true;
            } else {
                this.feedVisable = true;
            }
        },
        goback() {
            console.log();
            this.$router.replace({
                name: 'model-info',
                params: { id: this.$route.query.modelId },
                query: { type: this.$route.query.type },
            });
        },
    },
    created() {
        this.getData();
        this.$store
            .dispatch('card/getCardType')
            .then(res => {
                this.cardType = res;
            })
            .catch(e => {
                console.log(e);
            });
    },
    mounted() {
        this.$nextTick(() => {
            document
                .getElementsByClassName('learning')[0]
                .scrollIntoView({ behavior: 'auto' });
        });
    },
};
</script>

<style lang="less" scoped>
.card {
    position: relative;
}

.main {
    display: flex;
    padding-top: 30px;
    padding-bottom: 110px;

    .left {
        width: 950px;

        .title {
            padding-bottom: 20px;

            h1 {
                display: flex;
                margin-bottom: 15px;
                align-items: flex-start;

                span {
                    font-size: 20px;
                    color: rgb(51, 51, 51);
                    font-weight: bold;
                    line-height: 30px;
                }

                position: relative;
                padding-right: 20px;
            }

            .car_type {
                padding: 0 10px;
                border-radius: 3px;
                color: #fff;
                background-color: #ff0000;
                font-size: 16px;
                line-height: 30px;
                margin-right: 10px;
                flex-shrink: 0;
            }

            .info_box {
                display: flex;
                margin-bottom: 10px;
                line-height: 30px;
                .label {
                    min-width: 88px;
                    font-size: 18px;
                    color: rgb(0, 0, 0);
                    line-height: 30px;
                    font-weight: bold;
                }
            }

            .source {
                font-size: 18px;
                line-height: 30px;
                color: rgb(85, 85, 85);
            }

            .progress {
                & > div {
                    display: flex;
                    align-items: center;

                    span {
                        flex-shrink: 0;
                        margin-left: 10px;
                        font-size: 16px;
                        color: rgb(136, 136, 136);
                    }
                }
            }
        }

        .content {
            background-color: rgb(245, 245, 245);
            // padding: 20px 10px 30px 20px;
            position: relative;

            .info {
                // padding-right: 90px;
                // padding-top: 30px;
                color: #000;
                height: 660px;
                position: relative;

                &::-webkit-scrollbar {
                    width: 6px;
                    border-radius: 3px;
                    background-color: #d5d5d5;
                }

                &::-webkit-scrollbar-thumb {
                    width: 100%;
                    border-radius: 3px;
                    background-color: #aaaaaa;
                }

                .left-pop {
                    position: absolute;
                    width: 300px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    overflow-y: auto;
                    background-color: #eee;
                    z-index: 2;
                    padding: 20px 0 20px 20px;
                    // transform: translateX(-100%);
                    transition: 0.3s;

                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 3px;
                        background-color: #d5d5d5;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 100%;
                        border-radius: 3px;
                        background-color: #aaaaaa;
                    }

                    &.active {
                        transform: translateX(0);
                    }
                }
            }

            :deep(.editor) {
                // padding-top: 0;
                line-height: 22pt;
                background-color: #eee;
                [id*='test'] {
                    height: 660px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 6px;
                        border-radius: 3px;
                        background-color: #d5d5d5;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 100%;
                        border-radius: 3px;
                        background-color: #aaaaaa;
                    }
                }
            }

            .btn_box {
                position: absolute;
                right: 100%;
                padding-right: 10px;
                top: 0;

                .item {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                    color: #000;
                    border-radius: 3px;
                    background-color: rgb(245, 245, 245);
                    text-align: center;
                    line-height: 1.2;
                    padding: 0 10px;
                    margin-bottom: 1px;
                    transition: 0.3s;
                    cursor: pointer;
                    font-weight: bold;

                    &.active,
                    &:hover {
                        background-color: #00ffc0;
                        color: #000;
                    }
                }
            }
        }

        :deep(.ant-empty) {
            margin-top: 100px;
        }
    }

    .right {
        width: 490px;
        padding-left: 30px;
    }
}

.collect {
    color: #999;
    position: absolute;
    right: 0;
    top: 0.1rem;
    cursor: pointer;

    &.active {
        color: #f6a75c;
    }
}

/* fade-transform */
.list-leave-active,
.list-enter-active {
    transition: all 0.5s;
}

.list-enter {
    opacity: 0;
    //   transform: translateX(-30px);
}

.list-leave-to {
    opacity: 0;
    //   transform: translateX(30px);
}

.goback {
    position: absolute;
    left: 0.5rem;
    top: 0.2rem;
}
</style>
