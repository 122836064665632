<template>
    <div class="theme">
        <Title class="h2">
            <div class="h2_inner">
                <div class="he_header">
                    <svg-icon icon-class="icon_theme" class="icon"></svg-icon>
                    <span>相关内容</span>
                </div>
                <div class="page">
                    <a-pagination
                        simple
                        :default-current="1"
                        :total="total"
                        :pageSize="params.pageSize"
                        @change="pageChange"
                    />
                </div>
            </div>
        </Title>
        <div class="list">
            <a-list :data-source="list" :loading="loading">
                <a-list-item slot="renderItem" slot-scope="item">
                    <CubeItem :data="item" />
                </a-list-item>
            </a-list>
            <!-- <CubeItem v-for="item in list" :key="item.Id" :data="item"/> -->
        </div>
    </div>
</template>
<script>
import Title from '../Info/Title.vue'
import CubeItem from '../List/CubeItem.vue'
import { GetListByCardId } from '@/api/model'
export default {
    components: {
        Title,
        CubeItem,
    },
    data() {
        return {
            data: [],
            params: {
                cardId: '',
                pageIndex: 1,
                pageSize: 1,
            },
            total: 0,
            loading: false,
            list: [],
        }
    },
    methods: {
        pageChange(page, pageSize) {
            this.params.pageIndex = page
            this.getRealateList()
            // this.getData()
        },
        async getRealateList() {
            this.loading = true
            this.params.cardId = this.$route.params.id
            await GetListByCardId(this.params)
                .then(res => {
                    const { data, total } = res
                    this.list = data
                    this.total = total
                })
                .catch(e => e)
            this.loading = false
        },
    },
    created() {
        this.getRealateList()
    },
}
</script>
<style lang="less" scoped>
.theme {
    padding-bottom: 20px;
}
/deep/.item {
    margin-right: 0 !important;
}
/deep/.ant-list-item {
    border: 0 !important;
    padding: 0;
}
</style>
