<template>
    <div>
        <div style="width: 250px" class="level">
            <!-- <a-progress :percent="percent" :showInfo="false" strokeColor="#f6a75c" /> -->
            <span>{{level}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props:['level'],
    data(){
        return{
            cardLevel:[],
            percent:0
        }
    },
    created(){
        this.$store.dispatch('card/getCardLevel').then(res => { 
            this.percent = res.findIndex(item => item.Title == this.level) * 33.33
        }).catch(e=>e)
    }
}
</script>
<style scoped lang="less">
.level{
    display: flex;
    align-items: center;
    span{
        flex-shrink: 0;
        margin-left: 10px;
        font-size: 16px;
        color: rgb(136, 136, 136);
    }
}
</style>