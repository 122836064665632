<template>
    <div class="relate_card">
        <Title class="h2">
            <div class="h2_inner">
                <div class="h2_header">
                    <svg-icon
                        icon-class="icon_relate_card"
                        class="icon"
                    ></svg-icon>
                    <span>相关卡片</span>
                </div>
                <!-- <div class="page" v-if="list.length > 0">
                    <a-pagination 
                        simple 
                        :default-current="1" 
                        :total="total" 
                        :pageSize="params.pageSize"
                        @change="pageChange"
                    />
                </div> -->
            </div>
        </Title>
        <div class="card_list">
            <!-- <a-skeleton active :loading="loading"> -->
            <a-list
                :data-source="list"
                :loading="loading"
                :pagination="pagination"
            >
                <a-list-item slot="renderItem" slot-scope="item">
                    <div class="item" @click="goDetail(item)">
                        <div class="left">
                            <h2 class="dot">{{ item.text }}</h2>
                            <!-- <div class="des dot2" v-html="item.Card.Content"></div> -->
                            <div class="source">
                                卡片来源：{{ item.source }}
                            </div>
                        </div>
                        <!-- <svg-icon icon-class="icon_search"></svg-icon> -->
                    </div>
                </a-list-item>
            </a-list>
            <!-- </a-skeleton> -->
        </div>
    </div>
</template>
<script>
import Title from '../Info/Title.vue';
import { GetLink } from '@/api/link';
export default {
    components: {
        Title,
    },
    data() {
        return {
            list: [],
            params: {
                cardId: '',
                treeNum: '',
            },
            total: 0,
            loading: false,
            pagination: {
                pageSize: 5,
                simple: true,
            },
        };
    },
    watch: {
        id(val) {
            console.log(val);
            this.params.cardId = val;
            this.params.pageIndex = 0;
            this.getData();
        },
    },
    methods: {
        async getData() {
            this.loading = true;
            const { data } = await GetLink(this.params);
            this.list = data ? data.pointList : [];
            this.loading = false;
        },
        pageChange(page, pageSize) {
            this.params.pageIndex = page;
            this.getData();
        },
        goDetail(item) {
            let url = this.$router.resolve({
                name: 'card-info',
                params: { id: item.id },
            });
            window.open(url.href);
        },
    },
    mounted() {
        this.params.cardId = this.$route.params.id;
        this.params.treeNum = this.$route.query.treeNum;
        this.getData();
    },
};
</script>
<style scoped lang="less">
/deep/.h2 {
    padding-bottom: 0 !important;
    justify-content: space-between;
}
.item {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    flex: 1;
    .left {
        width: 0;
        flex: 1;
    }
    .svg-icon {
        flex-shrink: 0;
        font-size: 16px;
        color: #bbb;
        cursor: pointer;
    }
    h2 {
        line-height: 1;
        font-size: 17px;
        color: rgb(51, 51, 51);
        // font-weight: bold;
        padding-left: 22px;
        position: relative;
        margin-bottom: 5px;
        cursor: pointer;
        &::before {
            content: '';
            width: 5px;
            height: 5px;
            background-color: #f49133;
            position: absolute;
            left: 0;
            top: 6px;
        }
        &::after {
            content: '';
            width: 0;
            height: 0;
            border: 2.5px solid transparent;
            border-left-color: #f49133;
            position: absolute;
            left: 5px;
            top: 6px;
        }
    }
    .des {
        padding-left: 22px;
        font-size: 16px;
        color: rgb(85, 85, 85);
        line-height: 2;
        height: 3em;
        cursor: pointer;
    }
    .source {
        padding-left: 22px;
        line-height: 1;
        font-size: 16px;
        margin-top: 15px;
    }
    &:hover {
        h2 {
            color: #f49133;
        }
        .des {
            color: #f49133;
        }
        .svg-icon {
            color: #f49133;
        }
    }
}
/deep/.ant-list-item {
    border-bottom: 0 !important;
    flex: 1;
    width: 100%;
    padding: 0 !important;
}
/deep/.ant-list-pagination {
    position: absolute;
    right: 0;
    top: -32px;
    margin: 0;
}
/deep/.ant-pagination-simple-pager {
    display: none !important;
}
/deep/.ant-pagination-prev,
/deep/.ant-pagination-next {
    width: 30px;
    height: 30px !important;
    line-height: 30px !important;
    margin-left: 10px;
    margin-right: 0 !important;
}
</style>
